{
	"This link has expired": "Ce lien a expiré",
	"Secure Connection Established": "Connexion sécurisée établie",
	"Select a File to begin (5 files max)": "Sélectionnez un fichier pour commencer (5 fichiers maximum)",
	"Maximum File Size: 20 MB": "Taille maximale du fichier : 20 Mo",
	"Drag and Drop file or Browse": "Faire glisser et déposer le fichier ou Parcourir",
	"Upload": "Télécharger",
	"Neighbor Tools": "Outils de l'utilisateur",
	"File Upload": "Téléchargement de fichier",
	"file(s) selected for upload": "fichier(s) sélectionné(s) pour le téléchargement",
	"Maximum of 5 files": "5 fichiers maximum",
	"Upload in progress": "Téléchargement en cours",
	"Upload Successful": "Téléchargement réussi",
	"File is larger than 20 MB": "La taille du fichier est supérieure à 20 Mo",
	"File name has multiple file extensions": "Le nom du fichier a plusieurs extensions de fichier",
	"File name cannot start with . or ..": "Le nom du fichier ne peut pas commencer par . ou ..",
	"Maximum of 5 files": "5 fichiers maximum",
	"This link is expiring soon:": "Ce lien expire bientôt :",
	"minutes": "minutes",
	"less than a minute": "moins d'une minute",
	"1 minute": "1 minute",
	"Secure Connection Established": "Connexion sécurisée établie",
	"Drop the file here": "Déposer le fichier ici",
	"An error occurred while uploading": "Une erreur s'est produite lors du téléchargement",
	"File Type is not Supported": "Ce type de fichier n'est pas pris en charge",
	"BadRequestError: File name contains invalid characters": "BadRequestError : le nom du fichier contient des caractères non valides",
	"An error has occurred": "Une erreur s'est produite",
	"Upload request has already been used": "La demande de téléchargement a déjà été utilisée",
	"100 MB (audio/video)": "100 MB (audio/vidéo)"
}