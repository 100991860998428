{
	"This link has expired": "Questo link è scaduto",
	"Secure Connection Established": "Connessione sicura stabilita",
	"Select a File to begin (5 files max)": "Seleziona un file per iniziare (massimo 5 file)",
	"Maximum File Size: 20 MB": "Dimensioni massime del file: 20 MB",
	"Drag and Drop file or Browse": "Trascina e rilascia il file o sfoglia",
	"Upload": "Carica",
	"Neighbor Tools": "Strumenti di Neighbor",
	"File Upload": "Caricamento file",
	"file(s) selected for upload": "file selezionato/i per il caricamento",
	"Maximum of 5 files": "Massimo di 5 file",
	"Upload in progress": "Caricamento in corso",
	"Upload Successful": "Caricamento riuscito",
	"File is larger than 20 MB": "Il file è più grande di 20 MB",
	"File name has multiple file extensions": "Il nome del file ha più estensioni",
	"File name cannot start with . or ..": "Il nome file non può iniziare con . oppure ..",
	"Maximum of 5 files": "Massimo di 5 file",
	"This link is expiring soon:": "Questo link sta per scadere:",
	"minutes": "minuti",
	"less than a minute": "meno di un minuto",
	"1 minute": "1 minuto",
	"Secure Connection Established": "Connessione sicura stabilita",
	"Drop the file here": "Rilascia il file qui",
	"An error occurred while uploading": "Si è verificato un errore durante il caricamento",
	"File Type is not Supported": "Tipo di file non supportato",
	"BadRequestError: File name contains invalid characters": "BadRequestError: il nome del file contiene caratteri non validi",
	"An error has occurred": "Si è verificato un errore",
	"Upload request has already been used": "La richiesta di upload è già stata utilizzata",
	"100 MB (audio/video)": "100 MB (audio/video)"
}