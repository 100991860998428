{
    "default": {
        "api_prefix": "https://fileupload.cs-tools.dev.ring.com/api",
        "media_files_enabled": true
    },
    "fileupload.cs-tools.dev.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.dev.ring.com/api",
        "media_files_enabled": true
    },
    "fileupload.cs-tools.qa.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.qa.ring.com/api",
        "media_files_enabled": true
    },
    "fileupload.cs-tools.ring.com": {
        "api_prefix": "https://fileupload.cs-tools.ring.com/api",
        "media_files_enabled": false
    }
}
