{
	"This link has expired": "Deze link is verlopen",
	"Secure Connection Established": "Beveiligde verbinding tot stand gebracht",
	"Select a File to begin (5 files max)": "Selecteer een bestand om te beginnen (maximaal 5 bestanden)",
	"Maximum File Size: 20 MB": "Maximale bestandsgrootte: 20 MB",
	"Drag and Drop file or Browse": "Bestand slepen of opzoeken in mappen",
	"Upload": "Uploaden",
	"Neighbor Tools": "Hulpmiddelen voor gebruikers",
	"File Upload": "Bestand uploaden",
	"file(s) selected for upload": "bestand(en) geselecteerd voor uploaden",
	"Maximum of 5 files": "Maximaal 5 bestanden",
	"Upload in progress": "Bezig met uploaden",
	"Upload Successful": "Uploaden geslaagd",
	"File is larger than 20 MB": "Bestand is groter dan 20 MB",
	"File name has multiple file extensions": "Bestandsnaam bevat meerdere bestandsextensies",
	"File name cannot start with . or ..": "Bestandsnaam mag niet beginnen met . of ..",
	"Maximum of 5 files": "Maximaal 5 bestanden",
	"This link is expiring soon:": "Deze link verloopt binnenkort:",
	"minutes": "minuten",
	"less than a minute": "minder dan een minuut",
	"1 minute": "1 minuut",
	"Secure Connection Established": "Beveiligde verbinding tot stand gebracht",
	"Drop the file here": "Sleep het bestand hierheen",
	"An error occurred while uploading": "Er is een fout opgetreden tijdens het uploaden",
	"File Type is not Supported": "Bestandstype wordt niet ondersteund",
	"BadRequestError: File name contains invalid characters": "BadRequestError: bestand bevat ongeldige tekens",
	"An error has occurred": "Er is een fout opgetreden",
	"Upload request has already been used": "Uploadverzoek is al gebruikt",
	"100 MB (audio/video)": "100 MB (audio/video)"
}