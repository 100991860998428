{
	"This link has expired": "لقد انتهت صلاحية هذا الرابط",
	"Secure Connection Established": "تم إنشاء اتصال آمن",
	"Select a File to begin (5 files max)": "تحديد ملف للبدء (5 ملفات كحد أقصى)",
	"Maximum File Size: 20 MB": "الحد الأقصى لحجم الملف الواحد: 20 ميجابايت",
	"Drag and Drop file or Browse": "اسحب الملف الى هنا أو قُم بإختياره من بين ملفاتك",
	"Upload": "التحميل",
	"Neighbor Tools": "أدوات Neighbor",
	"File Upload": "تحميل ملف",
	"file(s) selected for upload": "الملف (الملفات) المحدد (المحددة) للتحميل",
	"Maximum of 5 files": "5 ملفات كحد أقصى",
	"Upload in progress": "جاري التحميل",
	"Upload Successful": "نجحت عملية التحميل",
	"File is larger than 20 MB": "الملف أكبر من 20 ميجابايت",
	"File name has multiple file extensions": "يحتوي اسم الملف على عدة امتدادات ملفات",
	"File name cannot start with . or ..": "لا يمكن أن يبدأ اسم الملف بـ . أو ..",
	"Maximum of 5 files": "5 ملفات كحد أقصى",
	"This link is expiring soon:": "تنتهي صلاحية هذا الرابط قريبًا:",
	"minutes": "من الدقائق",
	"less than a minute": "أقل من دقيقة",
	"1 minute": "دقيقة واحدة",
	"Secure Connection Established": "تم إنشاء اتصال آمن",
	"Drop the file here": "أسقط الملف هنا",
	"An error occurred while uploading": "حدث خطأ أثناء التحميل",
	"File Type is not Supported": "نوع الملف غير مدعوم",
	"BadRequestError: File name contains invalid characters": "BadRequestError: يتضمن اسم الملف حروف / أرقام / رموز غير صالحة",
	"An error has occurred": "حدث خطأ",
	"Upload request has already been used": "تم استخدام طلب التحميل بالفعل",
	"100 MB (audio/video)": "100 ميجابايت (صوت/فيديو)"
}